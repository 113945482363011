.App {
    text-align: center;

    overflow-x:hidden;
    overflow-y:hidden;

}


/*
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-float infinite 3s ease-in-out;
    }
}
*/


.App-logo {

    margin:0 0 0 0;
    float:left;

    /*height: 7vmin;*/
    height:54px;
    pointer-events: none;

}

@media (prefers-reduced-motion: no-preference) {

    .App-logo {

        animation: App-logo-spin infinite 20s linear;

    }
}





/*
.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

*/

.App-link {
    /*color: rgb(112, 76, 182);*/
    color:lightseagreen;
}

@keyframes App-logo-float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0px)
    }
}




.App-react-stuff {

    background-color:transparent;
    padding:5px 5px 5px 5px;

    margin:12px 15px 0 0;
    float:right;

    font-size:16px;
    color:white;

}


