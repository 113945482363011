.VidFlyr {


    background-color:white;

    width   :100%;
    height  :auto;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:3;
    top:0;
    left:0;


}



.VidFlyr .Grid {

    background-color:transparent;

    width   :98%;
    height  :auto;

    padding :0 0 0 0; 
    margin  :0 0 0 1%;

    position:relative;   
    float:left;

}


.VidFlyr .Column {

    background-color:transparent;

    width   :48%;
    height  :auto;

    padding :0 0 50px 0; 
    margin  :0 0 0 0;

    position:relative;   
    float:left;

    display : block;

    overflow:hidden;

    /*border:1px solid white;*/


}





.VidFlyr .Cell {

    background-color:white;

    width   :100%;
    /*height  :auto;*/
    height:100vh;

    padding :0 0 0 0; 
    margin  :0 0 10px 0;

    position:relative;   
    float:left;

    display : block;

    overflow:hidden;

    /*border:1px solid lightgray;*/


    cursor:pointer;
}





    .VidFlyr .Cell .Thmb {

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;/*vertical horizontal center*/
        overflow: hidden;

        background-color:#000;
        height:64vh;


    }
    @media (max-width: 1050px) {

        .VidFlyr .Cell .Thmb {

            height:64vh;

        }

    }
    @media (max-width: 800px) {

        .VidFlyr .Cell .Thmb {

            height:64vh;

        }

    }
    @media (max-width: 500px) {

        .VidFlyr .Cell .Thmb {

            height:64vh;

        }

    }


        .VidFlyr .Cell .Thmb img {

            flex-shrink: 0;

            min-width: 100%;
            /*min-height: 64%;*/

            position:relative;
            float:left;

            margin:auto;


        }











.VidFlyr .Thumb {

    background-color:black;

    width   :98%;
    height  :55%;

    padding :0 0 0 0; 
    margin  :0 0 0 1%;

    position:relative;   
    float:left;

    display : block;

    overflow:hidden;
}

















.VidFlyr .Title {

    padding:0 0 4px 0;
    margin:20px 0 10px 0;

    color: #454D5F;
    font-size:24px;
    font-weight:bold;   
    text-align: center;

}
@media (min-width: 1024px) {

    .VidFlyr .Title {

        font-size:1.5em;

    }
    
}


.VidFlyr .Text {

    margin:0 0 20px 0;
    padding:10px 15px 20px 15px;
    color: #000;
    font-size:16px;



}
@media (min-width: 1024px) {

    .VidFlyr .Text {

        font-size: 1em;

    }

}

