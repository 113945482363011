/*************************************
//input
*************************************/

.input-field {
    margin-bottom: 25;
}
.input {
    font-size: 18;
    /*placeholder-color: #A8A8A8;*/
}
.input:disabled {
    background-color: white;
    opacity: 0.5;
}




/*************************************
//select
*************************************/

select {

    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;
    
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;

    /*background: url(http;//dropdown/graphic/url/) no-repeat;*/
    background-position: right center;
    background-color: #CCCCCC;
    color: #000000;
    border: 1px solid #000000;
  
}

