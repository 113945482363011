

.alfa {

    background-color:#1A1926;

    width   :100%;
    height  :100%;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:100;
    top:0;
    left:0;


    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=60)";
    filter : alpha(opacity=60);
    -moz-opacity : 0.6;
    -khtml-opacity : 0.6;
    opacity : 0.6;

}



.base {


    background-color:transparent;

    width   :100%;
    height  :100%;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:101;
    top:0;
    left:0;


    overflow-x:hidden;
    overflow-y:auto;


}



.tab {


    background-color:white;

    width   :100%;
    height  :50px;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:110;
    top:0;
    left:0;

    border-bottom:1px solid lightgray;

}

    .tab-x {


        background-color:transparent;

        width   :auto;
        height  :50px;

        padding :7px 7px 0 7px; 
        margin  :0 10px 0 0;

        position:relative;   
        float:right;



        font-family:"Verdana", sans-serif;      
        font-size: 30px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:#1A1926;


        cursor:pointer;

    }


    .tab-title {


        background-color:transparent;

        width   :auto;
        height  :auto;

        padding :0 0 2px 0; 
        margin  :13px 0 0 10px;

        position:relative;   
        float:left;



        font-family:"Verdana", sans-serif;      
        font-size: 20px;
        font-weight: normal;
        text-align: left;
        text-decoration:none;
        color:#1A1926;


        cursor:pointer;

    }








.flank {


    background-color:transparent;

    width   :100%;
    height  :100%;

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:107;
    top:0;
    left:0;


}



